<template lang="">
    <div>
        <div class="headers">
            <span @click="goUp()"></span>
            <span>无法拖运，返程</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="returnJourney">
            
            <p>拖运现场影像：（拍摄三张现场情况）</p>
              <div v-if="!isWechat">
                <van-uploader v-model="fileList_a" multiple :max-count="1" :after-read="afterRead_a" :before-delete="beforeDelete_a"/>
                <van-uploader v-model="fileList_b" multiple :max-count="1" :after-read="afterRead_b" :before-delete="beforeDelete_b"/>
                <van-uploader v-model="fileList_c" multiple :max-count="1" :after-read="afterRead_c" :before-delete="beforeDelete_c"/>
              </div>
              <div v-else>
                  <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                      <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                          <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                      </van-image>
                  </span>
                  <span v-if="WXimg.length <= 2" class="uploaderImg" @click="WeChooseImage(3,'tuoyun')"> 
                      <van-icon class="iconz" name="plus" />
                  </span>
              </div>


            <p>无法拖运原因描述：</p>
            <textarea v-model="remark" style="font-size:0.38rem;height:2rem;width:95%"></textarea>
            <div class="submit" @click="submit()">提交并返程</div>

        </div>
    </div>
    
</template>
<script>
import { Toast } from "vant";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
      remark: "",
      fileList: [],
      vehicleImages: "",
      uploadImgBox: [],
      img_box: [],
      fileList_a:[],
      fileList_b:[],
      fileList_c:[],
      fileList_a_url: "",
      fileList_b_url: "",
      fileList_c_url: "",
      wximagelocalids:[],//判断上传张数
      toast:null,
      WXimg: [],
    };
  },
  created() {
      this.$http.post('/index.php/dispatch/find_no_way',{id:this.$route.query.id}).then(res=>{
          //console.log(res)
          if(res.data.code == 0){
                // let http = "http://192.168.1.50:8000/";
        let http = this.GLOBAL.BASE_URL;
              if(res.data.data){
                   if (res.data.data.img1) {
                        this.fileList_a = [
                            {
                            url: http + res.data.data.img1.replace("_1",""),
                            },
                        ];
                        this.fileList_a_url = res.data.data.img1
                    }
                    if (res.data.data.img2) {
                    this.fileList_b = [
                        {
                        url: http + res.data.data.img2.replace("_1",""),
                        },
                    ];
                    this.fileList_b_url = res.data.data.img2
                    }
                    if (res.data.data.img3) {
                    this.fileList_c = [
                        {
                        url: http + res.data.data.img3.replace("_1",""),
                        },
                    ];
                    this.fileList_c_url = res.data.data.img3
                    }
                    if(res.data.data.remark){
                        this.remark = res.data.data.remark
                    }
       
              }
          }
      })
  },
  watch:{
      wximagelocalids:{
        handler(newValue, oldValue){
              if(newValue.length > 0 ){
            this.toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "上传中..."
            });
            this.wxUpload(newValue[0])
        }else{
            this.toast.clear();
        }
          },
        deep: true,
    }
  },
  mounted () {
      this.wcConfig()
  },
  methods: {
    afterRead_a(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.fileList_a_url = res.data.data;
            Toast.clear();

        }
      });
    },
    afterRead_b(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.fileList_b_url = res.data.data;
                    Toast.clear();

        }
      });
    },
    afterRead_c(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.fileList_c_url = res.data.data;
                    Toast.clear();

        }
      });
    },
    beforeDelete_a() {
      this.fileList_a = [];
        this.fileList_a_url = ''
    //   this.img_box.splice(this.img_box.indexOf(this.fileList_a_url), 1);
    },
    beforeDelete_b() {
      this.fileList_b = [];
        this.fileList_b_url = ''

    //   this.img_box.splice(this.img_box.indexOf(this.fileList_a_url), 1);
    },
    beforeDelete_c() {
      this.fileList_c = [];
        this.fileList_c_url = ''

    //   this.img_box.splice(this.img_box.indexOf(this.fileList_a_url), 1);
    },
    submit(){
        //console.log(this.vehicleImages)
        let a1 = this.WXimg.length!=0 ?(this.WXimg[0]?this.WXimg[0]:'' ): this.fileList_a_url
        let a2 = this.WXimg.length!=0 ?(this.WXimg[1]?this.WXimg[1] : '') : this.fileList_b_url
        let a3 = this.WXimg.length!=0 ?(this.WXimg[2]?this.WXimg[2] : '') : this.fileList_c_url
        
        let obj = {
            dispatch_id:this.$route.query.id,
            img1:a1,
            img2:a2,
            img3:a3,
            remark:this.remark,
            }
        let data = encipherMent( JSON.stringify(obj))
        let param = new URLSearchParams();
        param.append("value",data);
        this.$http.post('/index.php/dispatch/no_way',param).then(res=>{
                  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                if(result.code == 0){
                    Toast.success(result.msg)
                    this.$router.go('-1')
                }else{
                    Toast.fail(result.msg)

                }
            })

    },
    goUp(){
        this.$router.go('-1')
    }
  },
};
</script>
<style lang="scss" scoped> 
  .headers {
      padding: 0 20px;
      background-color: cadetblue;
      height: 1rem;
      line-height: 1rem;
      color: white;
      font-size: 0.4rem;
      display: flex;

      justify-content: space-between;
      span {
        display: inline-block;
      }
      span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
      }
    }
.returnJourney {
  width: 96%;
  margin: 0 auto;
  p {
    font-size: 0.38rem;
  }
 .van-uploader {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
    // float: left;
    .van-uploader__wrapper {
      height: 100%;
      width: 100%;
      float: left;
      .van-uploader__upload {
        height: 100%;
        width: 100%;
      }
      .van-uploader__preview {
        height: 100%;
        width: 100%;
        .van-uploader__preview-image {
          height: 100%;
          width: 100%;
        }
      }
    }
     .van-uploader__preview-delete{
        height: 0.5rem;
        width: 0.5rem;
        .van-uploader__preview-delete-icon{
 height: 0.7rem;
        width: 0.5rem;
        }
      }
  }
  .submit {
    width: 100%;
    height: 0.8rem;
    font-size: 0.38rem;
    line-height: 0.8rem;
    text-align: center;
    color: white;
    margin: 20px auto;
    background-color: rgba(0, 153, 255, 1);
    border-radius: 6px;
  }
}
.uploaderImg{
    display: inline-block;
    width: 80px !important;
    height: 80px !important;
    border: 1px solid #eee;
    position: relative;
    .iconz{
        position: absolute;
        top: 42%;
        left: 38%;

    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>